
export default [
    {
        path: '/financialclose',
        component: "__base",
        otherdata: { color: "caccounting" },
        children: [
            {
                path: "home",
                component: "Projects.vue",
                meta: { title: "Cycle List" },
                right: "financialclose_project"
            },
            {
                path: "home",
                component: "Home.vue",
                meta: { title: "Home" },
                right: "financialclose_project"
            },
            {
                path: "project/dashboard/:id",
                component: "Project/Dashboard.vue",
                meta: { title: "Dashboard", submenu: ["tabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "project/checklist/:id",
                component: "Project/TasklistNew.vue",
                meta: { title: "Tasklist", submenu: ["tabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "project/trialbalance/:id",
                component: "Project/TrialBalance.vue",
                meta: { title: "Trial Balance", submenu: ["tabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "project/users/:id",
                component: "Project/User.vue",
                meta: { title: "Users", submenu: ["tabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "project/threshold/:id",
                component: "Project/Threshold.vue",
                meta: { title: "Threshold", submenu: ["tabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "project/informationmanager/:id",
                component: "Project/InformationManager.vue",
                meta: { title: "Information Manager", submenu: ["tabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "project/varianceanalysis/:id",
                component: "VarianceAnalysis/List.vue",
                meta: { title: "Variance Analysis", submenu: ["tabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "functionalsupport/:id",
                component: "VirtualConcierge.vue",
                meta: { title: "Virtual Concierge", right: "helpdesk", submenu: ["tabs"]}, 
            },
            {
                path: "functionalsupport/:id/support/:QueryId",
                component: "common/QueriesView.vue",
                meta: { title: "Ticket Details", right: "helpdesk", submenu: ["tabs"]},
            },
            {
                path: "automationlease/:id",
                component: "Automation/LeaseSchedule.vue",
                meta: { title: "Lease Schedule", right: "financialclose_analysis", submenu: ["tabs"]},
            },
            {
                path: "automationborrowingschedule/:id",
                component: "Automation/BorrowingSchedule.vue",
                meta: { title: "Borrowing Schedule", right: "financialclose_analysis", submenu: ["tabs"]},
            },
            {
                path: "automationtransactionmatching/:id",
                component: "Automation/TransactionMatching.vue",
                meta: { title: "Transaction Matching", right: "financialclose_analysis", submenu: ["tabs"]},
            },
            {
                path: "automationtransactionmatching/:id/c/:anaid",
                component: "Automation/TransactionMatchingConfig.vue",
                meta: { title: "Transaction Matching", right: "financialclose_analysis", submenu: ["tabs"]},
            },
            {
                path: "project/:id/varianceanalysis/analysis/:vid",
                component: "VarianceAnalysis/Analysis.vue",
                meta: { title: "Variance Analysis", submenu: ["tabs", "vatabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "project/:id/varianceanalysis/configuration/:vid",
                component: "VarianceAnalysis/Configuration.vue",
                meta: { title: "Variance Analysis - Configuration", submenu: ["tabs", "vatabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "project/:id/varianceanalysis/users/:vid",
                component: "VarianceAnalysis/User.vue",
                meta: { title: "Variance Analysis - Users", submenu: ["tabs", "vatabs"] },
                right: "financialclose_analysis"
            },
            {
                path: "manage/chartofaccount",
                component: "Manage/ChartOfAccount.vue",
                meta: { title: "Manage Chart of Accounts" },
                right: "financialclose_master_chartofaccount"
            },
            {
                path: "manage/checklist",
                component: "Manage/Checklist.vue",
                meta: { title: "Manage Tasklist" },
                right: "financialclose_master_checklist"
            },
            {
                path: "manage/location",
                component: "Manage/location.vue",
                meta: { title: "Manage Location", edit:true },
                right: "financialclose_master_checklist"
            },
            {
                path: "manage/informationmanager",
                component: "Manage/InformationManager.vue",
                meta: { title: "Manage Information Requests" },
                right: "financialclose_master_informationmanager"
            },
            {
                path: "manage/threshold",
                component: "Manage/Threshold.vue",
                meta: { title: "Threshold" },
                right: "financialclose_master_threshold"
            },
            {
                path: "support", 
                component: "TechnicalSupport.vue",
                meta: { title: "Support", right: "helpdesk"}, 
            },
            {
                path: "support/:QueryId",
                component: "common/QueriesView.vue",
                meta: { title: "Ticket Details", right: "helpdesk"},
            },
            {
                path: "*",
                component: "",
            },
        ],
    },
]

